
import { Activity, ActualActivity, GenericState, Phase, Program, StateType } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import { User } from "@/store/user/types";
import { ROOT_ACTIONS } from "@/store/actions";
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { USER_GETTERS } from "@/store/user/getters";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";
import ProgramScreenCastsCard from "@/components/cards/ProgramScreenCastsCard.vue";


@Component({
	components: {
		EditProgramDialog: () => import('@/components/dialogs/EditProgram.vue'),
		EditActualActivity: () => import('@/components/dialogs/EditActualActivity.vue'),
		ConfirmationDialog: () => import('@/components/dialogs/ConfirmationDialog.vue'),
		DeleteProgram: () => import('@/components/dialogs/DeleteProgram.vue'),

		ProgramTimeline: () => import('@/components/ProgramTimeline.vue'),
		ProgramPhaseControls: () => import('@/components/ProgramPhaseControls.vue'),
		ProgramControls: () => import('@/components/ProgramControls.vue'),
		ProgramUserCard: () => import('@/components/cards/ProgramUserCard.vue'),
		ProgramInfoCard: () => import('@/components/cards/ProgramInfoCard.vue'),
		ProgramWebinarsCard: () => import('@/components/cards/ProgramWebinarsCard.vue'),
		ProgramScreenCastsCard: () => import('@/components/cards/ProgramScreenCastsCard.vue'),
		ProgramProgressChart: () => import('@/components/ProgramProgressChart.vue'),
	},
})

export default class ProgramView extends Vue {
	program: null | Program = null;
	states = [] as GenericState[];
	phases = [] as Phase[];
	actvities = [] as Activity[];
	actualActivities = [] as ActualActivity[];

	coach: null | User = null;
	participant: null | User = null;

	selectedActivity = -1;
	currentStep = 1;

	breakingError = false;

	selectedActualActivity: ActualActivity | null = null;

	dialogs = {
		suspend: false,
		resume: false,
		addActualActivity: false,
		editProgram: false,
		editActualActivity: false,
		archive: false,
		dearchive: false,
		delete: false,
	}

	loading = true

	formatHours(hours: number) {
		const rhours = Math.floor(hours);
		const minutes = (hours - rhours) * 60;

		if (minutes === 0) return `${rhours}h`;

		const rminutes = Math.ceil(minutes);
		return `${rhours}h${rminutes}m`;
	}

	formatDate(date: string | Date) {
		return format(new Date(date), 'EEEE dd/MM/yyyy hh:mm', {
			locale: nl,
		})
	}

	openAddActualActivityDialog(activityId: number | undefined) {
		if (activityId) {
			this.selectedActivity = activityId;
			this.dialogs.addActualActivity = true;
		}
	}

	get user(): User {
		return this.$store.getters[USER_GETTERS.ACTING_USER]
	}

	async mounted() {
		await this.fetchProgram();
	}

	async fetchActivitiesForPhase(phaseId: string) {
		const fetchedActivities = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', phaseId)));

		return fetchedActivities.docs.map((doc) => {
			return {
				...doc.data(),
				id: doc.id
			} as Activity
		})
	}

	async fetchActualActivitiesForActivity(activityId: string) {
		const fetchedActualActivities = await getDocs(query(collection(firestore, 'actual-activities'), where('activityId', '==', activityId)));

		return fetchedActualActivities.docs.map((doc) => {
			return {
				...doc.data(),
				id: doc.id
			} as ActualActivity
		})
	}

	async fetchProgram() {
		try {
			this.loading = true
			this.phases = []
			this.actvities = []
			this.actualActivities = []

			const fetchedProgram = await getDoc(doc(firestore, 'programs', this.$route.params.id))
			const fetchedPhases = await getDocs(query(collection(firestore, 'phases'), where('programId', '==', this.$route.params.id), orderBy('index', 'asc')))
			const fetchedStates = await getDocs(query(collection(firestore, 'program-states'), where('programId', '==', this.$route.params.id), orderBy('date', 'asc')))

			for (const phase of fetchedPhases.docs) {
				this.phases.push({ ...phase.data(), id: phase.id } as Phase);
				const fetchedActivities = await this.fetchActivitiesForPhase(phase.id);
				this.actvities.push(...fetchedActivities);
				for (const activity of fetchedActivities) {
					const actualActivities = await this.fetchActualActivitiesForActivity(activity.id!);
					this.actualActivities.push(...actualActivities);
				}
			}

			this.program = {
				...fetchedProgram.data(),
				id: fetchedProgram.id,
			} as Program;

			this.states = fetchedStates.docs.map((doc) => {
				return {
					...doc.data(),
					id: doc.id
				} as GenericState
			})

			if (this.program) {
				const coach = await getDoc(doc(firestore, 'users', this.program.coach))
				const participant = await getDoc(doc(firestore, 'users', this.program.participant))

				this.coach = {
					...coach.data(),
					id: coach.id
				} as User

				this.participant = {
					...participant.data(),
					id: participant.id
				} as User


				this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Presence', target: '/' }, { text: 'Programmas', target: '/admin/presence/overview' }, { text: this.program!.title, target: this.program!.id }])
			} else {
				throw new Error('Something went wrong')
			}
		} catch (error) {
			console.error(error)
			this.breakingError = true;
		} finally {
			this.loading = false
		}
	}
}
